<template>
  <div>
    <el-header id="header">
      <span class="hideAside" @click="collapse"><i class="fa fa-indent fa-lg"></i></span>
      <div class='user-count'>
        <div class="title">
          注册人数:
          <span>{{regCount}}</span>
        </div>
        <div class="title">
          在线人数:
          <span>{{onlineCount}}</span>
        </div>
      </div>
      <ul class="personal">
        <li class="fullScreen" @click="fullScreen">
          <el-tooltip class="item" effect="dark" content="全屏" placement="bottom"><i
            class="fa fa-arrows-alt fa-lg"></i></el-tooltip>
        </li>
        <li>管理员</li>
        <li>
          <el-dropdown @command="handleCommand">
                  <span class="el-dropdown-link">
                    {{$store.state.username}}<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="editPassword">修改密码</el-dropdown-item>
              <el-dropdown-item command="logout" divided>注销</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li class="icon"><img :src="avatar"/></li>
      </ul>
    </el-header>
    <edit-password v-if="dialogPassVisible" :dialogVisible="dialogPassVisible" @editPwdCallback="editPwdCallback"/>
  </div>
</template>

<script>
import EditPassword from '../../../components/userForm/editPassword'
import { userOnlineCount, userRegCount } from '../../../api/apis/users'
export default {
  name: 'Header',
  components: { EditPassword },
  data () {
    return {
      isfullScreen: true,
      avatar: require('@/assets/icon.jpg'),
      dialogPassVisible: false,
      title: '',
      userId: '',
      onlineCount: 0,
      regCount: 0
    }
  },
  created () {
    this.getUserCount(userOnlineCount, 'onlineCount')
    this.getUserCount(userRegCount, 'regCount')
  },
  methods: {
    async getUserCount (api, key) {
      const { content, status } = await api()
      if (status === 'ok') {
        this[key] = content
      } else {
        this[key] = 0
      }
    },
    collapse () {
      this.$emit('changeCollapse')
    },
    editPwdCallback () {
      this.dialogPassVisible = false
    },
    fullScreen () {
      if (this.isfullScreen) {
        const docElm = document.documentElement
        if (docElm.requestFullscreen) {
          docElm.requestFullscreen()
        } else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen()
        } else if (docElm.webkitRequestFullScreen) {
          docElm.webkitRequestFullScreen()
        } else if (docElm.msRequestFullscreen) {
          docElm.msRequestFullscreen()
        }
        this.isfullScreen = false
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
        this.isfullScreen = true
      }
    },
    handleCommand (command) {
      if (command === 'editPassword') {
        this.dialogPassVisible = true
      } else if (command === 'logout') {
        this.$store.dispatch('clearSession')
        this.$router.push({ name: 'login' })
      }
    }
  }
}
</script>

<style lang="scss">
  $top: top;
  $bottom: bottom;
  $left: left;
  $right: right;
  $leftright: ($left, $right);
  %w100 {
    width: 100%;
  }

  %h100 {
    height: 100%;
  }

  %cursor {
    cursor: pointer;
  }

  html, body, #app, .el-container, #asideNav, ul.el-menu {
    @extend %h100;
  }

  @mixin set-value($side, $value) {
    @each $prop in $leftright {
      #{$side}-#{$prop}: $value;
    }
  }

  #header {
    width: 100%;
    max-height: 50px;
    line-height: 50px;
    background-color: #30a1a2;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
    display: flex;
    justify-content: space-between;
    .hideAside {
      color: #c1c1c1;
      @extend %cursor;
    }
    .user-count {
      display: flex;
      min-width: 300px;
      margin: 0 40px;
      justify-content: space-around;
      font-size: 20px;
      .title {
        display: flex;
        color: #fff;
        span {
          color:#0dff79;
          margin-left: 7px;
          filter: brightness(1.5);
        }
      }
    }
    .personal {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      li {
        @include set-value(margin, 13px);
        font-size: 12px;
      }
      .fullScreen {
        @extend %cursor;
      }
      .el-dropdown-link {
        @extend %cursor;
      }
      .icon img {
        margin-#{$top}: 7px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        width: 40px;
        height: 40px;
      }
    }
  }
</style>
