<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'app'
}

</script>

<style lang="scss">
  body {
    margin: 0px;
    padding: 0px;
    font-family: Microsoft YaHei, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,  SimSun, sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
  }

  #app {
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100%;
  }
  input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{
    -webkit-appearance: none !important;
  }
  /* 火狐浏览器样式清除 */
  input[type="number"]{
    -moz-appearance:textfield;
  }
  a{
    color: #56a9ff;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all .2s ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  #nprogress .bar {
    height: 3px !important;
    background: #56a9ff !important; //自定义颜色
  }
  .float-right {
    float: right;
    margin: 10px!important;
  }
  .demo-ruleForm {
    width: calc(100% - 50px);
    padding-top: 25px;
  }
  .el-select {
    width: 100%;
  }
  .card {
    width: calc(90vw - 300px);
    max-width: 1150px;
    min-width:650px;
    padding-bottom: 15px;
    margin: 0px auto;
    position: relative;
    .return-icon {
      margin: 2px 0 5px 2px;
      font-size: 18px;
      &:hover {
        filter: brightness(1.2);
      }
    }
  }
  .card .delete-icon {
    position: absolute;
    font-size: 25px;
    right: 5px;
    top: 5px;
    cursor: pointer;
    &:hover {
      color: #f00;
    }
  }
  .avatar-uploader {
    height: 80vh;
    width: 100%;
    overflow-y: auto;
  }
  .el-upload-list__item {
    border: 1px solid #000;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    .el-upload-dragger{
      display: flex;
      flex-wrap: wrap;
      height: auto;
      width: auto;
    }
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px!important;
    text-align: center;
  }
  .avatar {
    height: 178px;
    display: block;
  }
  .text-over {
    width: 100%;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
  }
  .add-btn {
    position: absolute;
    left: 100px;
    top: 26px;
  }
</style>
