<template>
  <div>
    <el-aside id="asideNav">
      <div class="logo-name">
        <p v-if="!isCollapse">后台管理系统</p>
        <p v-else>后台</p>
      </div>
      <el-menu class="el-menu-vertical"
               @select="selectmenu"
               :collapse="isCollapse"
               background-color="#03152A"
               text-color="rgba(255,255,255,.7)"
               active-text-color="#ffffff"
               :router="true"
               :collapse-transition="true">
        <template v-for="(item) in routes">
          <el-menu-item :index="item.name"
                        :key="item.name"
                        :class="item.name === currentRoute ? 'is-active' : ''">
            <router-link :to="{ name: item.name }">{{item.title}}</router-link>
          </el-menu-item>
        </template>
      </el-menu>
    </el-aside>
  </div>
</template>

<script>

export default {
  name: 'asideNav',
  props: {
    isCollapse: {
      type: Boolean
    }
  },
  data () {
    return {
      currentRoute: 'drug',
      routes: [{
        name: 'drug',
        title: '药品'
      }, {
        name: 'popular',
        title: '科普'
      }, {
        name: 'purine',
        title: '嘌呤'
      }, {
        name: 'diet',
        title: '饮食'
      }, {
        name: 'news',
        title: '研究'
      }, {
        name: 'qa',
        title: '问答'
      }, {
        name: 'user',
        title: '用户'
      }]
    }
  },
  methods: {
    selectmenu (key) {
      this.currentRoute = key
    }
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler (val) {
        this.currentRoute = val.slice(1)
      }
    }
  }
}
</script>

<style lang="scss">
$top: top;
$bottom: bottom;
$left: left;
$right: right;
%w100 {
  width: 100%;
}

%h100 {
  height: 100%;
}

%cursor {
  cursor: pointer;
}

@mixin set-value($side, $value) {
  @each $prop in $leftright {
    #{$side}-#{$prop}: $value;
  }
}

#asideNav {
  width: auto !important;
  display: flex;
  flex-direction: column;
  border-right: solid 1px #e6e6e6;
  .logo-name {
    background-color: #03152a !important;
    @extend %w100;
    font-weight: 300;
    z-index: 999;
    p {
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 16px;
      color: #5e6df2;
    }
  }
  .el-menu-vertical:not(.el-menu--collapse) {
    width: 200px;
    @extend %h100;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .el-menu {
    flex: 1;
    overflow: inherit;
    border-right: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .fa {
      vertical-align: middle;
      margin-right: 5px;
      width: 24px;
      text-align: center;
      font-size: 18px;
    }
    .el-menu-item {
      background-color: #020f1d !important;
      border-bottom: 1px solid #020f1d;
      &:hover {
        color: #ffffff !important;
        background-color: #375573 !important;
      }
    }
    .el-menu-item.is-active {
      background-color: #56a9aa !important;
      a {
        color: #fff;
      }
    }
    .is-opened > .el-submenu__title > .el-icon-arrow-down {
      color: #ffffff;
      font-weight: 500;
      font-size: 18px;
    }
  }
}
</style>
