<template>
  <div>
    <p>Copyright © 2021 loris.</p>
  </div>
</template>

<script>
export default {
  name: 'bottom'
}
</script>

<style scoped>
p{
  height: 20px;
  line-height: 20px;
  text-align: left;
  font-size: 12px;
  color: #999999;
}
</style>
