
import axios from 'axios'
import store from '../vuex'
import { getBaseUrl, loginOut } from './baseConfig'
// axios默认配置
axios.defaults.timeout = 10000 // 超时时间
axios.defaults.baseURL = getBaseUrl()

// http request 拦截器
axios.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/json;charset=UTF-8'
  config.headers.Authentication = store.state.authentication
  return config
},
error => {
  return Promise.reject(error.response)
})
export const reLogin = loginOut
export default axios
