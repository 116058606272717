import axios, { reLogin } from '../axios'
import { Base64 } from 'js-base64'
import md5 from 'blueimp-md5'

/**
 * @param {object} params
 * @param {string} password
 * @param {string} phone
 * @param {string} realName
 * @param {string} username
 *  @param {0} role
 */
async function userCreate (params) {
  if (!params) return null
  try {
    const { data } = await axios.post('/user/create', {
      ...params,
      password: Base64.encode(md5(params.password))
    })
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 * @param {object} params
 * @param {string} pn
 * @param {string} ps
 * @param {string} keyword
 */
async function userList (params) {
  if (!params) return null
  try {
    const { data } = await axios.get('/user/list', { params })
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}

async function userOnlineCount () {
  try {
    const { data } = await axios.get('/user/online/count')
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}

async function userRegCount () {
  try {
    const { data } = await axios.get('/user/reg/count')
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 * @param {number} id
 */
async function userDelete (id) {
  if (!id) return null
  try {
    const { data } = await axios.delete(`/user/${id}`)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
async function putUserPassword (params) {
  if (!params) return null
  const oldPass = Base64.encode(md5(params.oldPass))
  const newPass = Base64.encode(md5(params.newPass))
  try {
    const { data } = await axios.put('/user/password', { oldPass, newPass, id: params.id })
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
async function initPassword (id) {
  if (!id) return null
  try {
    const { data } = await axios.post(`/user/${id}/init`)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
/**
 * 分配权限
 * @param {object} params
 * @param {0} id
 * @param {0} role
 */
async function rolePut (params) {
  if (!params) return null
  try {
    const { data } = await axios.put('/role/put', params)
    if (data.status !== 'ok') reLogin(data.errorMsg)
    return data
  } catch (error) {
    return null
  }
}
export {
  userCreate,
  userList,
  userOnlineCount,
  userRegCount,
  userDelete,
  putUserPassword,
  initPassword,
  rolePut
}
