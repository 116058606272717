import Vue from 'vue'
import Router from 'vue-router'
import store from '../vuex'

import Login from '@/views/login/index'
import Layout from '@/views/layout/layout'

const NotFound = () => import('@/views/page404')
const Drug = () => import('@/views/drug')
const Diet = () => import('@/views/diet')
const News = () => import('@/views/news')
const Popular = () => import('@/views/popular')
const Purine = () => import('@/views/purine')
const Qa = () => import('@/views/qa')
const User = () => import('@/views/user')

Vue.use(Router)

const routes = [{
  path: '/',
  redirect: {
    name: 'drug'
  }
}, {
  path: '/login',
  name: 'login',
  component: Login
}, {
  path: '/home',
  name: 'layout',
  component: Layout,
  children: [{
    path: '/drug',
    name: 'drug',
    component: Drug
  }, {
    path: '/diet',
    name: 'diet',
    component: Diet
  }, {
    path: '/news',
    name: 'news',
    component: News
  }, {
    path: '/popular',
    name: 'popular',
    component: Popular
  }, {
    path: '/purine',
    name: 'purine',
    component: Purine
  }, {
    path: '/qa',
    name: 'qa',
    component: Qa
  }, {
    path: '/user',
    name: 'user',
    component: User
  }]
}, {
  path: '*',
  component: NotFound,
  name: '404'
}]

const router = new Router({
  routes
})

router.beforeEach((to, from, next) => {
  if (!store.state.isLogin) {
    const sessionValue = window.localStorage.getItem('Authentication')
    if (sessionValue) {
      const username = window.localStorage.getItem('username')
      const userId = window.localStorage.getItem('userid')
      store.dispatch('setSession', { sessionValue, username, userId })
      next()
    } else if (to.name !== 'login') {
      next({ name: 'login' })
    } else {
      next()
    }
  } else next()
})

export default router
