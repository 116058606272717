import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    isLogin: false,
    username: '',
    userId: null,
    authentication: null
  },
  mutations: {
    updateLogin (state, payload) {
      state.isLogin = payload.state
      state.username = payload.username
      state.userId = payload.userId
    },
    updateAuthentication (state, payload) {
      state.authentication = payload
    }
  },
  actions: {
    clearSession ({ commit }) {
      window.localStorage.removeItem('Authentication')
      window.localStorage.removeItem('username')
      window.localStorage.removeItem('userid')
      commit('updateAuthentication', null)
      commit('updateLogin', { state: true, username: '', userId: null })
    },
    setSession ({ commit }, { sessionValue, username, userId }) {
      window.localStorage.setItem('Authentication', sessionValue)
      window.localStorage.setItem('username', username)
      window.localStorage.setItem('userid', userId)
      commit('updateAuthentication', sessionValue)
      commit('updateLogin', { state: true, username, userId })
    }
  }
})

export default store
