import router from '../router'
function getBaseUrl () {
  if (process.env.NODE_ENV === 'production') {
    // return 'http://172.31.0.216/gout'
    return 'https://www.yuantongfeng.top/gout/'
    // return 'http://1.116.149.153/gout'
  }
  return '/api'
};

function loginOut (msg) {
  // const msgArr = ['您已下线,请重新登录', 'Session超时！', '用户名或密码错误！']
  const msgArr = ['下线', 'Session', '密码错误']
  msgArr.forEach(ms => {
    if (msg.indexOf(ms) !== -1) {
      window.localStorage.clear()
      return router.push({ name: 'login' })
    }
  })
}

export {
  getBaseUrl,
  loginOut
}
