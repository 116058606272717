
import axios from '../axios'
import { Base64 } from 'js-base64'
import md5 from 'blueimp-md5'

/** 登录 */
async function login (params) {
  const { username, password } = params
  if (!username || !password) return null
  const pd = Base64.encode(md5(password))
  try {
    const { data } = await axios.post('/login', { username, password: pd })
    return data
  } catch (e) {
    return null
  }
};

export {
  login
}
